import Vue from 'vue'

const RESOURCE_NAME = '/status'

export const CLIENT_STATUS_TYPE = 'clients'
export const PROVIDER_STATUS_TYPE = 'providers'
export const OFFER_STATUS_TYPE = 'offers'
export const PRODUCT_STATUS_TYPE = 'products'
export const SALE_INVOICES_STATUS_TYPE = 'sale_invoices'
export const PURCHASE_INVOICES_STATUS_TYPE = 'purchase_invoices'
export const ORDERS_STATUS_TYPE = 'orders'
export const DELIVERY_NOTES_STATUS_TYPE = 'delivery_notes'
export const JOBS_STATUS_TYPE = 'jobs'
export const JOB_ADDITIONALS_STATUS_TYPE = 'job_additionals'
export const CERTIFICATIONS_STATUS_TYPE = 'certifications'
export const NON_CONFORMITIES_STATUS_TYPE = 'non_conformities'
export const MAINTENANCE_CERTIFICATIONS_TYPE = 'maintenance_certifications'

export default {
  list(statusType) {
    return Vue.prototype.$http.post(`${RESOURCE_NAME}/list`, { type: statusType })
  },
}
