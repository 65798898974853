export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localModel: {},
    }
  },
  computed: {
    model() {
      return this.value || this.localModel
    },
  },
  methods: {
    update(key, value) {
      this.localModel = { ...this.model, [key]: value }
      this.$emit('input', this.localModel)
    },
    massUpdate(value) {
      this.localModel = value
      this.$emit('input', value)
    },
    clearForm() {
      this.localModel = {}
      this.$emit('input', {})
    },
  },
}
