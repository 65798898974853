<template>
  <b-sidebar
    v-model="model"
    right
    backdrop
    bg-variant="white"
  >
    <template #header>
      <b-container
        fluid
        class="p-0 text-dark-gray bg-light-gray"
      >
        <b-row
          align-v="center"
          align-h="between"
          class="p-1"
        >
          <b-col cols="auto">
            Filtrar
          </b-col>
          <b-col cols="auto">
            <b-link
              class="text-dark-gray"
              @click="model = false"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </b-link>
          </b-col>
        </b-row>
        <div class="px-1 base-filters-side-bar-content">
          <slot />
        </div>
        <b-row class="px-2">
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="py-1 px-1 rounded mr-1"
              data-cy="filtersSubmitButton"
              @click="handleSearchButtonClick"
            >
              Buscar
            </b-button>

            <b-button
              variant="outline-primary"
              class="py-1 px-1 rounded"
              @click="$emit('input', false)"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: 'BaseFiltersSideBar',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    handleSearchButtonClick() {
      this.$emit('filters-submit')
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped>
.base-filters-side-bar-content {
  overflow: auto;
  height: calc(100vh - 125px);
  margin-bottom: 10px;
}
</style>
