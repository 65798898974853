import { render, staticRenderFns } from "./BaseFiltersSideBar.vue?vue&type=template&id=1d37dc36&scoped=true&"
import script from "./BaseFiltersSideBar.vue?vue&type=script&lang=js&"
export * from "./BaseFiltersSideBar.vue?vue&type=script&lang=js&"
import style0 from "./BaseFiltersSideBar.vue?vue&type=style&index=0&id=1d37dc36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d37dc36",
  null
  
)

export default component.exports